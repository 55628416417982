import { subMonths } from "date-fns";
import { endOfDay, startOfDay } from "date-fns/esm";
import { useState } from "react";
import styled from "styled-components";
import { DateRangeInput } from "../../../../components/Common/DateRangeInput";
import { Table, TableSettings } from "../../../../components/Common/Table";
import { Title } from "../../../../components/Common/Title";
import { Spinner } from "../../../../components/Spinner";
import { useDeviceErrorTaskExecutionHistory } from "../../../../queries/deviceErrorTask";
import { triggerStateToString } from "./constants";

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const View = styled.div`
  display: flex;
  flex-direction: column;
  width: 75rem;

  ${Title} {
    margin: 2rem 0;
  }

  ${Actions} {
    margin-bottom: 1rem;
  }
`;

const NoHistoryMessage = styled.div`
  font-family: Poppins;
  color: var(--col-1-c-2020);
`;

type HistoryEntry = NonNullable<
  ReturnType<typeof useDeviceErrorTaskExecutionHistory>["data"]
>[number];

const constantTableProps: TableSettings = {
  columns: [
    {
      Header: "Task name",
      accessor: ({ errorTask }: HistoryEntry) =>
        errorTask === null ? "Task deleted" : errorTask.name,
      id: "errorTask.name",
      width: 3,
    },
    { Header: "Task group", accessor: "errorTask.groupName", width: 3 },
    {
      Header: "Device",
      accessor: ({ device }: HistoryEntry) =>
        device.site
          ? `${device.serialNumber} - ${device.site}`
          : device.serialNumber,
      id: "device",
      width: 4,
    },
    {
      Header: "Trigger state",
      accessor: ({ errorState }: HistoryEntry) =>
        triggerStateToString[errorState],
      id: "errorState",
      width: 3,
    },
    {
      Header: "Executed at",
      accessor: "createdAt",
      width: 3,
    },
  ],
  enableFilters: true,
  infiniteScroll: true,
};

export function ErrorTaskExecutionHistory() {
  const [startDate, setStartDate] = useState(
    startOfDay(subMonths(new Date(), 1))
  );
  const [endDate, setEndDate] = useState(endOfDay(new Date()));

  const history = useDeviceErrorTaskExecutionHistory({
    start: startDate,
    end: endDate,
  });

  if (history.status === "loading") {
    return <Spinner />;
  } else if (history.data) {
    return (
      <View>
        <Title>Error task execution history</Title>
        <Actions>
          <DateRangeInput
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            maxDate={new Date()}
          />
        </Actions>
        {history.data.length === 0 ? (
          <NoHistoryMessage>
            No tasks have executed during this timespan
          </NoHistoryMessage>
        ) : (
          <Table data={history.data} {...constantTableProps} />
        )}
      </View>
    );
  }

  return (
    <View>
      <NoHistoryMessage>An error occurred when loading data</NoHistoryMessage>
    </View>
  );
}
