import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { SecondaryButtonSmall } from "../../components/Common/Button";
import { Table } from "../../components/Common/Table";
import { useMemo } from "react";

interface GroupsTableProps {
  data: any[];
  deleteGroup(groupId: number): void;
  topLevel?: boolean;
}

const SubComponentContainer = styled.div`
  padding: 14px;
  flex-basis: 100%;
`;

const GroupsTableStyles = css`
  font-family: "Poppins";

  .thead {
    display: none;
  }

  .tbody .tr {
    background-color: white;
    border: 1px solid #ededf0;
    flex-wrap: wrap;
    box-shadow: none;

    &.even {
      background-color: white;
    }

    &:hover {
      opacity: 1;
    }
  }
`;

const GroupTableExpander = styled.span<{ isExpanded: boolean }>`
  flex: 35 0 auto;
  width: 35px;
  max-width: 35px;

  transform: rotate(${(props) => (props.isExpanded ? "0" : "-90deg")});
`;

export function GroupsTable(props: GroupsTableProps) {
  const { data, topLevel, deleteGroup } = props;

  const isEuropressGroup = (name: string) => {
    return name === "Europress";
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        width: 1,
        Cell: ({ row }: any) => {
          if (row.original.children.length === 0 || topLevel) {
            return null;
          }
          return (
            <GroupTableExpander
              {...row.getToggleRowExpandedProps()}
              isExpanded={row.isExpanded}
            >
              {row.isExpanded ? "⯆" : "⯈"}
            </GroupTableExpander>
          );
        },
      },
      {
        Header: "Group name",
        id: "name",
        accessor: "name",
      },
      {
        Header: "Actions",
        Cell: ({ row }: any) => {
          row.isExpanded = topLevel;
          return (
            <div>
              <Link
                to={{
                  pathname: `/admin/groups/new`,
                  state: {
                    parentId: row.original.groupId,
                  },
                }}
              >
                <SecondaryButtonSmall>Add subgroup</SecondaryButtonSmall>
              </Link>{" "}
              <Link to={`/admin/groups/${row.original.groupId}`}>
                <SecondaryButtonSmall
                  disabled={isEuropressGroup(row.original.name)}
                >
                  Edit
                </SecondaryButtonSmall>
              </Link>{" "}
              <SecondaryButtonSmall
                disabled={isEuropressGroup(row.original.name)}
                onClick={() => deleteGroup(row.original.groupId)}
              >
                Delete
              </SecondaryButtonSmall>
            </div>
          );
        },
      },
    ],
    [topLevel, deleteGroup]
  );

  return (
    <Table
      data={data}
      noDataText="No groups"
      sortable={false}
      paginate={false}
      pageSize={data.length}
      tableStyles={GroupsTableStyles}
      columns={columns}
      initialState={{
        sortBy: [
          {
            id: "name",
            desc: false,
          },
        ],
      }}
      rowSubComponent={(row: any) => {
        const children = row.original.children;
        if (children.length === 0) {
          return null;
        }
        return (
          <SubComponentContainer>
            <GroupsTable
              data={children}
              deleteGroup={deleteGroup}
              topLevel={false}
            />
          </SubComponentContainer>
        );
      }}
    />
  );
}
