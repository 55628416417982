import React, { useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as TYPES from "../../../constants/actionTypes";
import { ScalePage } from "../../Scale/ScalePage";
import { Formik, Field } from "formik";
import { v4 as uuidv4 } from "uuid";
import { Table, TableSettings } from "../../../components/Common/Table";
import { FormSubmitButton } from "../../../components/Common/FormSubmitButton";
import { ScaleFirmwareSchema } from "./schemas";
import { isDefined } from "../../../utils/utils";

const Heading = styled.div`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--col-1-c-2020);
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
`;

const FormContainer = styled.div`
  > * {
    margin: 1rem;
  }
`;

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateFirmware: (token: string, firmware: string, scales: any) => {
      dispatch({
        type: TYPES.POST_SCALE_UPLOAD_FILE,
        payload: {
          token: token,
          firmware: firmware,
          scales: scales,
        },
      });
    },
    fetchScales: (token: string) => {
      dispatch({
        type: TYPES.FETCH_SCALES,
        payload: {
          token: token,
        },
      });
    },
    fetchFirmwares: (token: string) => {
      dispatch({
        type: TYPES.FETCH_SCALE_FIRMWARES,
        payload: {
          token: token,
        },
      });
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    scales: state.admin.scales,
    firmwares: state.admin.firmwares,
    token: state.token.key,
  };
};

const nonEmptyString = (val: any) =>
  val !== undefined && val !== null && val !== "";

const constantTableProps: TableSettings = {
  columns: [
    {
      Header: "",
      id: "selected",
      width: 1,
      Cell: ({ row }: any) => (
        <Field name={"scales"}>
          {({ field, form }: any) => {
            const value = row.original.scale_serial_number;
            return (
              <input
                type="checkbox"
                name="scales"
                value={value}
                // Prevent starting another update if one is already ongoing
                disabled={row.original.file_to_update}
                checked={field.value.includes(value)}
                onChange={() => {
                  if (field.value.includes(value)) {
                    const nextValue = field.value.filter(
                      (selectedVal: any) => selectedVal !== value
                    );
                    form.setFieldValue("scales", nextValue);
                  } else {
                    const nextValue = field.value.concat(value);
                    form.setFieldValue("scales", nextValue);
                  }
                }}
              />
            );
          }}
        </Field>
      ),
    },
    { Header: "IRU id", accessor: "scale_serial_number", width: 5 },
    {
      Header: "Device s.n.",
      accessor: "device.serialNumber",
      width: 2,
    },
    { Header: "Owner group", accessor: "device.ownerGroup", width: 3 },
    {
      Header: "Location",
      accessor: ({ device }: any) =>
        [device?.site, device?.city].filter(nonEmptyString).join(", "),
      width: 3,
    },
    { Header: "Waste fraction", accessor: "device.wasteFraction", width: 3 },
    { Header: "Current firmware", accessor: "program_version", width: 2 },
    { Header: "Pending firmware", accessor: "file_to_update", width: 2 },
  ],
  initialState: {
    sortBy: [
      // First show devices that are waiting to be updated, then other devices ordered by serial number
      { id: "file_to_update", desc: true },
      { id: "device.serialNumber" },
    ],
  },
  enableFilters: true,
  infiniteScroll: true,
  getRowProps: (row: any) =>
    nonEmptyString(row.original.file_to_update)
      ? {
          style: {
            backgroundColor: "var(--col-11-a-3-c-7)",
          },
        }
      : {},
};

type ScaleFirmwaresConnectedProps = {
  token: string;
  scales: any[];
  firmwares: any[];
  fetchScales: (token: string) => void;
  fetchFirmwares: (token: string) => void;
  updateFirmware: (token: string, firmware: string, scales: any) => void;
};

const ScaleFirmwaresConnected = (props: ScaleFirmwaresConnectedProps) => {
  useEffect(() => {
    if (props.token) {
      props.fetchScales(props.token);
      props.fetchFirmwares(props.token);
    }
  });

  return (
    <ScalePage>
      <div>
        <Formik
          initialValues={{
            scales: [],
            firmware: "",
          }}
          onSubmit={(values) => {
            props.updateFirmware(props.token, values.firmware, values.scales);
            values.scales = [];
            values.firmware = "";
          }}
          validationSchema={ScaleFirmwareSchema}
        >
          {(formik) => (
            <FormContainer>
              <Heading>Select scales to update</Heading>
              <Table data={props.scales} {...constantTableProps} />
              <Heading>Select firmware</Heading>
              <select
                name="firmware"
                value={formik.values.firmware}
                onChange={formik.handleChange}
                onBlur={() => {}}
                style={{ display: "block" }}
              >
                <option value="" label="" />
                {props.firmwares.map((firmware: any) => (
                  <option key={uuidv4()} value={firmware} label={firmware} />
                ))}
              </select>
              {0 < formik.submitCount && (
                <div>
                  {Object.values(formik.errors)
                    .filter(isDefined)
                    .map((error, idx) => (
                      <div key={idx}>{error}</div>
                    ))}
                </div>
              )}
              <FormSubmitButton onClick={formik.submitForm}>
                Start firmware update
              </FormSubmitButton>
            </FormContainer>
          )}
        </Formik>
      </div>
    </ScalePage>
  );
};

export const ScaleFirmwares = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScaleFirmwaresConnected);
