import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormWrapper } from "../../../components/Common/FormWrapper";
import { useDispatch, useSelector } from "react-redux";
import { Formik, FormikProps } from "formik";
import { GroupLicensesApi } from "@shared/apiTypes";
import * as actions from "../../../constants/actionTypes";
import { Spinner } from "../../../components/Spinner";
import { Toggle } from "../../../components/Common/Toggle";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import {
  FormInput,
  FormLabel,
  FormValue,
  InputBox,
} from "../../../components/Common/FormInput";
import DateInput from "../../../components/Common/DateInput";
import { FlexBoxWrapper } from "../../../components/Common";
import { Table } from "../../../components/Common/Table";
import { FormSubmitButton } from "../../../components/Common/FormSubmitButton";
import { Divider } from "../../../components/Common/Divider";
import { parseISO } from "date-fns/esm";
import { findGroupById } from "../../../utils/group";
import { groupHierarchiesSelector } from "../../../selectors/groups";

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 1rem;
  width: 75%;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: "Roboto";
  color: white;

  > * {
    margin-left: 1rem;
  }
`;

const InfoText = styled.p`
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Roboto";
  color: white;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;

  > div {
    width: 100%;
  }

  > ${Divider} {
    width: 75%;
    margin: 2rem 0;
  }

  > .tableWrapper {
    width: 87.5%;
  }
  > ${InfoText} {
    width: 75%;
  }
`;

const isoStringToDateString = (isoString: string) =>
  parseISO(isoString).toLocaleDateString();

function DeviceLicenseFields(props: FormikProps<GroupLicensesApi>) {
  const preventTableStateReset = useRef(false);
  const [selectedDeviceRows, setSelectedDeviceRows] = useState<string[]>([]);
  const [insights, setInsights] = useState<string | null>(null);
  const [integrated, setIntegrated] = useState<string | null>(null);
  const tableData = useMemo(() => props.values.deviceLicenses, [
    props.values.deviceLicenses,
  ]);

  const tableColumns = useMemo(
    () => [
      { Header: "Serial number", accessor: "deviceSerialNumber", width: 1 },
      { Header: "Site", accessor: "deviceSite", width: 2 },
      {
        Header: "Insights",
        accessor: ({ insights }: any) =>
          insights ? isoStringToDateString(insights) : null,
        width: 1,
      },
      {
        Header: "Integrated",
        accessor: ({ integrated }: any) =>
          integrated ? isoStringToDateString(integrated) : null,
        width: 1,
      },
    ],
    []
  );

  const updateDeviceLicenses = (
    license: { insights: string } | { integrated: string }
  ) => {
    const updatedLicenses = props.values.deviceLicenses.map((deviceLicense) =>
      selectedDeviceRows.includes(deviceLicense.deviceId.toString())
        ? { ...deviceLicense, ...license }
        : { ...deviceLicense }
    );

    // Prevent table sorting and selections from being reset when we modify its data
    preventTableStateReset.current = true;
    props.setFieldValue("deviceLicenses", updatedLicenses);
  };

  const handleDevicesSelection = useCallback(
    (selectedDevIds: string[]) => {
      // Ensure that if some of the selected devices has integrated / insights license set,
      // the value of that license is displayed in the respective datepicker.
      if (0 < selectedDevIds.length) {
        const selectedDevs = props.values.deviceLicenses.filter((license) =>
          selectedDevIds.includes(license.deviceId.toString())
        );
        const firstDevWithInsightsSet = selectedDevs.find(
          (dev) => dev.insights !== null
        );
        const firstDevWithIntegratedSet = selectedDevs.find(
          (dev) => dev.integrated !== null
        );
        setInsights(
          firstDevWithInsightsSet !== undefined
            ? firstDevWithInsightsSet.insights
            : null
        );
        setIntegrated(
          firstDevWithIntegratedSet !== undefined
            ? firstDevWithIntegratedSet.integrated
            : null
        );
      }
      setSelectedDeviceRows(selectedDevIds);
    },
    [props.values.deviceLicenses]
  );

  return (
    <TableWrapper>
      <InfoText>Select the devices whose licenses you want to edit</InfoText>
      <Table
        data={tableData}
        onSelectionChange={handleDevicesSelection}
        preventStateReset={preventTableStateReset}
        columns={tableColumns}
        getRowId={(row: any) => row.deviceId.toString()}
        initialState={{ sortBy: [{ id: "deviceSerialNumber" }] }}
        sortable
        infiniteScroll
      />
      <Divider />
      <InfoText>Set licenses for the selected devices</InfoText>
      <FormInput
        label="Insights"
        type="date"
        value={insights}
        error=""
        componentProps={{
          onSelect: (_: any, val: any) => {
            setInsights(val);
            updateDeviceLicenses({ insights: val });
          },
          useISO: true,
        }}
        component={DateInput}
      />
      <FormInput
        label="Integrated"
        type="date"
        value={integrated}
        error=""
        componentProps={{
          onSelect: (_: any, val: any) => {
            setIntegrated(val);
            updateDeviceLicenses({ integrated: val });
          },
          useISO: true,
        }}
        component={DateInput}
      />
    </TableWrapper>
  );
}

function GroupLicenseFields(props: FormikProps<GroupLicensesApi>) {
  return (
    <>
      <FormInput
        id="groupLicense.insights"
        label="Insights"
        type="date"
        value={props.values.groupLicense.insights}
        error=""
        onChange={props.setFieldValue}
        component={DateInput}
        componentProps={{ useISO: true }}
      />
      <FormInput
        id="groupLicense.integrated"
        label="Integrated"
        type="date"
        value={props.values.groupLicense.integrated}
        error=""
        onChange={props.setFieldValue}
        component={DateInput}
        componentProps={{ useISO: true }}
      />
    </>
  );
}

export function EditGroupLicensesForm(
  props: RouteComponentProps<{ groupId: string }>
) {
  const { history } = props;
  const groupId = parseInt(props.match.params.groupId);
  const groupHierarchy = useSelector(groupHierarchiesSelector);
  const token = useSelector((state: any) => state.token.key);
  const licenses = useSelector(
    (state: any): GroupLicensesApi => state.admin.licenses?.data?.[groupId]
  );
  const isLoading = useSelector(
    (state: any): Boolean => state.admin.licenses.isLoading
  );
  const dispatch = useDispatch();

  const group = findGroupById(groupHierarchy, groupId);

  useEffect(() => {
    dispatch({
      type: actions.GET_GROUP_LICENSES,
      payload: { groupId, token },
    });
  }, [dispatch, groupId, token]);

  return isLoading || licenses === undefined ? (
    <Spinner />
  ) : (
    <Formik<GroupLicensesApi>
      initialValues={licenses}
      onSubmit={(values, _actions) => {
        dispatch({
          type: actions.UPDATE_GROUP_LICENSES,
          payload: { token, licenses: values, groupId },
        });
      }}
    >
      {(props) => (
        <FormWrapper>
          <InputBox>
            <FormLabel>Group</FormLabel>
            <FormValue>{group?.name}</FormValue>
          </InputBox>
          <FormInput
            id="groupLicense.intro"
            label="Intro"
            type="date"
            value={props.values.groupLicense.intro}
            error=""
            onChange={props.setFieldValue}
            component={DateInput}
            componentProps={{ useISO: true }}
          />
          <Divider />
          <ToggleWrapper>
            Use device-level licenses
            <Toggle
              selected={props.values.type === "devices"}
              onToggle={(selected) =>
                props.setFieldValue("type", selected ? "devices" : "group")
              }
            />
          </ToggleWrapper>
          {props.values.type === "group" ? (
            <GroupLicenseFields {...props} />
          ) : (
            <DeviceLicenseFields {...props} />
          )}
          <Divider />
          <FlexBoxWrapper>
            <FormSubmitButton type="button" onClick={() => history.goBack()}>
              Back
            </FormSubmitButton>
            &nbsp;
            <FormSubmitButton type="submit">Save licenses</FormSubmitButton>
          </FlexBoxWrapper>
        </FormWrapper>
      )}
    </Formik>
  );
}
