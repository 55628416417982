import { useMemo } from "react";
import { Table } from "../../../components/Common/Table";
import { pressureDataErrorCodeToText } from "./pressureDataErrorCodeToText";
import { FillLevels } from "./types";

export function FillLevelErrorsTable({
  fillLevels,
}: {
  fillLevels: FillLevels;
}) {
  const errorFillLevels = fillLevels.filter(
    (fillLevel) => fillLevel.didFillLevelError
  );

  const columns = useMemo(() => {
    return [
      { Header: "Measurement date", accessor: "measurementDate", width: 2 },
      {
        Header: "Error code",
        accessor: (row: any) =>
          pressureDataErrorCodeToText(row.fillLevelMeta.errorCode),
        id: "errorCode",
        width: 3,
        disableFilters: true,
      },
    ];
  }, []);

  if (errorFillLevels.length === 0) {
    return <div>No fill levels with errors</div>;
  }

  return (
    <Table
      data={errorFillLevels}
      enableFilters
      infiniteScroll
      initialState={{ sortBy: [{ id: "measurementDate" }] }}
      sortable
      columns={columns}
    />
  );
}
