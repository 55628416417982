import { useState, useMemo, useRef } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Formik } from "formik";

import * as TYPES from "../../../constants/actionTypes";
import { Table } from "../../../components/Common/Table";
import {
  SecondaryButtonSmall,
  ConfirmButtonSmall,
  ConfirmButtonLarge,
  CancelButtonSmall,
} from "../../../components/Common/Button";
import { Modal } from "../../../components/Common/Modal";
import { FormInput } from "../../../components/Common/FormInput";
import { FormSubmitButton } from "../../../components/Common/FormSubmitButton";

const RowActions = styled.div`
  display: flex;
  gap: 10px;
`;
const AnomalyTypeTableContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const CreateAnomalyTypeFormContainer = styled.div`
  margin: 20px 0;
`;
const AnomalyTypeTableButtons = styled.div`
  margin: 10px;
  align-self: flex-end;
`;
const DeleteConfirmRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
const DeleteConfirmText = styled.div`
  display: inline;
  text-align: right;
`;

const mapDispatchToProps = (dispatch: any) => {
  return {
    createAnomalyType: (token: string, payload: any) => {
      dispatch({
        type: TYPES.CREATE_ANOMALY_TYPE,
        payload: {
          token: token,
          data: payload,
        },
      });
    },
    updateAnomalyType: (token: string, payload: any) => {
      dispatch({
        type: TYPES.UPDATE_ANOMALY_TYPE,
        payload: {
          token: token,
          data: payload,
        },
      });
    },
    deleteAnomalyType: (token: string, payload: any) => {
      dispatch({
        type: TYPES.DELETE_ANOMALY_TYPE,
        payload: {
          token: token,
          data: payload,
        },
      });
    },
    fetchAnomalyTypes: (token: string) => {
      dispatch({
        type: TYPES.FETCH_ANOMALY_TYPES,
        payload: {
          token: token,
        },
      });
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    anomalyTypes: state.admin.anomalies.types,
    isLoaded: state.admin.isLoaded,
    token: state.token.key,
  };
};

type CreateAnomalyTypeFormProps = {
  onSubmit: (values: any) => void;
};
const CreateAnomalyTypeForm = (props: CreateAnomalyTypeFormProps) => {
  return (
    <CreateAnomalyTypeFormContainer>
      <Formik
        initialValues={{
          name: "",
        }}
        onSubmit={(values) => props.onSubmit(values)}
      >
        {({ values, handleChange }) => (
          <>
            <FormInput
              id="name"
              type="text"
              label="Anomaly Type Label"
              value={values.name}
              error={!values.name ? "Name cannot be empty" : undefined}
              onChange={handleChange}
              required={true}
              labelColor={"#00000"}
              noHorizontalMargin={true}
            />
            <FormSubmitButton
              type="submit"
              onClick={() => props.onSubmit(values)}
            >
              Create
            </FormSubmitButton>
          </>
        )}
      </Formik>
    </CreateAnomalyTypeFormContainer>
  );
};

interface AnomalyTypesModalProps {
  anomalyTypes: any;
  fetchAnomalyTypes(token: string): void;
  createAnomalyType(token: string, data: any): void;
  updateAnomalyType(token: string, data: any): void;
  deleteAnomalyType(token: string, data: any): void;
  isLoaded: boolean;
  token: any;
}
const AnomalyTypesModalConnected = (props: AnomalyTypesModalProps) => {
  const [editingId, setEditingId] = useState<number>();
  const [deletingId, setDeletingId] = useState<number>();
  const [creating, setCreating] = useState<boolean>(false);
  const { anomalyTypes, isLoaded, token } = props;

  const editNameInputRef = useRef<HTMLInputElement>(null);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        width: 50,
      },
      {
        Header: "Anomaly type",
        accessor: "name",
        Cell: ({ row }: any) => {
          if (editingId && editingId === row.values.id) {
            return (
              <input
                type="text"
                ref={editNameInputRef}
                defaultValue={row.values.name}
              />
            );
          } else if (deletingId && deletingId === row.values.id) {
            return (
              <DeleteConfirmRow>
                <>{row.values.name}</>
                <DeleteConfirmText>Are you sure?</DeleteConfirmText>
              </DeleteConfirmRow>
            );
          }

          return <>{row.values.name}</>;
        },
        width: 500,
      },
      {
        Header: "Actions",
        Cell: ({ row }: any) => {
          if (editingId || deletingId) {
            if (editingId === row.values.id) {
              return (
                <RowActions>
                  <ConfirmButtonSmall
                    onClick={() =>
                      handleUpdateAnomalyType({
                        name: editNameInputRef!.current!.value,
                        id: row.values.id,
                      })
                    }
                  >
                    SAVE
                  </ConfirmButtonSmall>
                  <SecondaryButtonSmall
                    onClick={() => {
                      setEditingId(undefined);
                    }}
                  >
                    CANCEL
                  </SecondaryButtonSmall>
                </RowActions>
              );
            } else if (deletingId === row.values.id) {
              return (
                <RowActions>
                  <CancelButtonSmall
                    onClick={() => handleDeleteAnomalyType(row.values.id)}
                  >
                    DELETE
                  </CancelButtonSmall>
                  <SecondaryButtonSmall
                    onClick={() => {
                      setDeletingId(undefined);
                    }}
                  >
                    CANCEL
                  </SecondaryButtonSmall>
                </RowActions>
              );
            } else {
              // Hide actions for other rows if editing or deleting
              return null;
            }
          } else {
            return (
              <RowActions>
                <SecondaryButtonSmall
                  onClick={() => setEditingId(row.values.id)}
                >
                  EDIT
                </SecondaryButtonSmall>
                <CancelButtonSmall
                  onClick={() => {
                    setDeletingId(row.values.id);
                  }}
                >
                  DELETE
                </CancelButtonSmall>
              </RowActions>
            );
          }
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deletingId, editingId]
  );

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const handleCreateAnomalyType = (values: any) => {
    props.createAnomalyType(token, values);
    setCreating(false);
  };

  const handleUpdateAnomalyType = (values: { id: number; name: string }) => {
    props.updateAnomalyType(token, values);
    setEditingId(undefined);
  };

  const handleDeleteAnomalyType = (id: number) => {
    props.deleteAnomalyType(token, { id });
  };

  const tableProps = {
    data: anomalyTypes || [],
    columns,
    infiniteScroll: true,
    initialState: {
      sortBy: [{ id: "id", desc: false }],
    },
  };

  return (
    <>
      <Modal
        // Create Anomaly Type sub-modal
        isOpen={creating}
        handleClose={() => setCreating(false)}
        content={<CreateAnomalyTypeForm onSubmit={handleCreateAnomalyType} />}
        title="Create anomaly type"
      />
      <AnomalyTypeTableContainer>
        <Table {...tableProps} />
        <AnomalyTypeTableButtons>
          <ConfirmButtonLarge onClick={() => setCreating(true)}>
            Create Anomaly Type
          </ConfirmButtonLarge>
        </AnomalyTypeTableButtons>
      </AnomalyTypeTableContainer>
    </>
  );
};

export const AnomalyTypesModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(AnomalyTypesModalConnected);
