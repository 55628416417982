import { Fragment, useEffect, useState, useMemo, useRef } from "react";
import * as TYPES from "../../constants/actionTypes";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { roleOptions } from "../../constants/options";
import {
  PrimaryButtonLarge,
  SecondaryButtonSmall,
} from "../../components/Common/Button";

import { ConfirmationModal } from "../../components/Common/ConfirmationModal";
import { Table } from "../../components/Common/Table";
import styled, { css } from "styled-components";

const RowActions = styled.div`
  > * ~ * {
    margin-left: 0.4rem;
  }
`;

interface UsersTableProps {
  getUsersForAdmin(token: string): void;
  sendRegisterEmail(userId: number, token: string): void;
  sendResetPasswordEmail(data: any): void;
  updateUser(
    userId: number,
    payload: { scaleAdmin?: boolean; hasServiceToolAccess?: boolean },
    token: string
  ): void;
  deleteUser(userId: number, token: string): void;
  disableUser(userId: number, token: string): void;
  enableUserMFA(userId: number, token: string): void;
  disableUserMFA(userId: number, token: string): void;
  isLoaded: boolean;
  users: any[];
  token: any;
}

const UsersTableStyles = css`
  font-family: "Poppins";

  .tbody .td {
    width: auto;
    min-width: auto;
    border-bottom: 1px solid #ededf0;
    border-right: 1px solid #ededf0;
  }

  .tbody .tr {
    background-color: white;
    box-shadow: none;

    &.even {
      background-color: white;
    }

    &:hover {
      opacity: 1;
    }
  }
`;

export function UsersTable(props: UsersTableProps) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteUserIdx, setDeleteUserIdx] = useState(-1);

  const [isDisableModalOpen, setIsDisableModalOpen] = useState(false);
  const [disableUserIdx, setDisableUserIdx] = useState(-1);

  const preventTableStateReset = useRef(false);

  const {
    disableUserMFA,
    enableUserMFA,
    getUsersForAdmin,
    sendRegisterEmail,
    sendResetPasswordEmail,
    token,
    updateUser,
  } = props;

  useEffect(() => {
    getUsersForAdmin(token);
  }, [getUsersForAdmin, token]);

  const booleanSortMethod = (rowA: any, rowB: any, id: string) => {
    const _rowA = rowA.values[id];
    const _rowB = rowB.values[id];
    if (_rowA > _rowB) {
      return 1;
    } else if (_rowA < _rowB) {
      return -1;
    } else {
      return 0;
    }
  };

  const data = props.users;
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        minWidth: 300,
      },
      {
        Header: "Groups",
        id: "groups",
        accessor: (row: any) =>
          row.groups.map((group: any) => group.name).join(", "),
        minWidth: 200,
      },
      {
        Header: "Role",
        accessor: "role",
        disableFilters: true,
        Cell: (cell: any) => {
          const role = roleOptions.find(
            (option) => cell.value === option.value
          );
          return role ? role.text : "No role";
        },
      },
      {
        Header: "Admin",
        id: "admin",
        accessor: (row: any) => (row.admin ? true : false),
        disableFilters: true,
        sortType: booleanSortMethod,
        Cell: (cell: any) => (
          <input type="checkbox" checked={cell.value} disabled />
        ),
      },
      {
        Header: "Scale Admin",
        id: "scale_admin",
        accessor: (row: any) => (row.scale_admin ? true : false),
        disableFilters: true,
        sortType: booleanSortMethod,
        Cell: (row: any) => (
          <input
            type="checkbox"
            name={"scale_admin"}
            checked={row.value}
            onChange={(e) => {
              preventTableStateReset.current = true;
              updateUser(
                row.row.original.id,
                { scaleAdmin: e.target.checked },
                token
              );
            }}
          />
        ),
      },
      {
        Header: "Service tool access",
        accessor: "hasServiceToolAccess",
        disableFilters: true,
        sortType: booleanSortMethod,
        Cell: (row: any) => (
          <input
            type="checkbox"
            checked={row.value}
            onChange={(e) => {
              preventTableStateReset.current = true;
              updateUser(
                row.row.original.id,
                { hasServiceToolAccess: e.target.checked },
                token
              );
            }}
          />
        ),
      },
      {
        Header: "Service tool admin",
        accessor: "hasServiceToolAdminAccess",
        disableFilters: true,
        sortType: booleanSortMethod,
        Cell: (cell: any) => (
          <input type="checkbox" checked={cell.value} disabled />
        ),
      },
      {
        Header: "MFA enabled",
        accessor: "mfaEnabled",
        disableFilters: true,
        sortType: booleanSortMethod,
        Cell: (row: any) => (
          <input
            type="checkbox"
            checked={row.value}
            onChange={(e) => {
              preventTableStateReset.current = true;
              if (e.target.checked) {
                enableUserMFA(row.row.original.id, token);
              } else {
                disableUserMFA(row.row.original.id, token);
              }
            }}
          />
        ),
      },
      {
        Header: "Registered",
        accessor: "isRegistered",
        disableFilters: true,
        sortType: booleanSortMethod,
        Cell: (cell: any) => (
          <input type="checkbox" checked={cell.value} disabled />
        ),
      },
      {
        Header: "Actions",
        minWidth: 350,
        Cell: ({ row }: any) => {
          return (
            <RowActions>
              {row.values.isRegistered ? (
                <SecondaryButtonSmall
                  onClick={() =>
                    sendResetPasswordEmail({ name: row.values.name })
                  }
                >
                  Forgot password email
                </SecondaryButtonSmall>
              ) : (
                <SecondaryButtonSmall
                  onClick={() => sendRegisterEmail(row.original.id, token)}
                >
                  Send register email
                </SecondaryButtonSmall>
              )}
              <Link to={`/admin/users/${row.original.id}`}>
                <SecondaryButtonSmall>Edit</SecondaryButtonSmall>
              </Link>
              <SecondaryButtonSmall
                onClick={() => {
                  setDisableUserIdx(row.index);
                  setIsDisableModalOpen(true);
                }}
              >
                Delete
              </SecondaryButtonSmall>
            </RowActions>
          );
        },
      },
    ],
    [
      disableUserMFA,
      enableUserMFA,
      sendRegisterEmail,
      sendResetPasswordEmail,
      token,
      updateUser,
    ]
  );

  if (!props.isLoaded) {
    return <div>Loading...</div>;
  }
  return (
    <Fragment>
      {deleteUserIdx !== -1 && (
        <ConfirmationModal
          onConfirm={(result) => {
            setIsDeleteModalOpen(false);
            if (result) {
              props.deleteUser(props.users[deleteUserIdx].id, token);
            }
            setDeleteUserIdx(-1);
          }}
          message={`Are you sure you want to delete user ${props.users[deleteUserIdx].name}? All user data will be removed.`}
          isOpen={isDeleteModalOpen}
        />
      )}
      {disableUserIdx !== -1 && (
        <ConfirmationModal
          onConfirm={(result) => {
            setIsDisableModalOpen(false);
            if (result) {
              props.disableUser(props.users[disableUserIdx].id, token);
            }
            setDisableUserIdx(-1);
          }}
          message={`Are you sure you want to delete user ${props.users[disableUserIdx].name}?`}
          isOpen={isDisableModalOpen}
        />
      )}
      <Link to={`/admin/users/new`}>
        <PrimaryButtonLarge margin="1rem">Create user</PrimaryButtonLarge>
      </Link>
      <Table
        data={data}
        columns={columns}
        sortable={true}
        enableFilters={true}
        noDataText="No users!"
        preventStateReset={preventTableStateReset}
        paginate={true}
        pageSize={10}
        tableStyles={UsersTableStyles}
        initialState={{
          sortBy: [{ id: "name", desc: false }],
        }}
      />
    </Fragment>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getUsersForAdmin: (token: string) => {
      dispatch({
        type: TYPES.FETCH_USERS_FOR_ADMIN,
        payload: { token },
      });
    },
    sendRegisterEmail: (userId: number, token: string) => {
      dispatch({
        type: TYPES.SEND_REGISTER_LINK_TO_USER,
        payload: { userId, token },
      });
    },
    sendResetPasswordEmail: (data: any) => {
      dispatch({
        type: TYPES.FORGOT_PASSWORD,
        payload: { data },
      });
    },
    updateUser: (
      userId: number,
      payload: { scaleAdmin?: boolean; hasServiceToolAccess?: boolean },
      token: string
    ) => {
      if (
        payload.scaleAdmin === undefined &&
        payload.hasServiceToolAccess === undefined
      ) {
        return;
      }
      dispatch({
        type: TYPES.UPDATE_USER_FOR_ADMIN,
        payload: {
          userId,
          data: payload,
          token,
        },
      });
    },
    deleteUser: (userId: number, token: string) => {
      dispatch({
        type: TYPES.DELETE_USER_FOR_ADMIN,
        payload: { token, userId },
      });
    },
    disableUser: (userId: number, token: string) => {
      dispatch({
        type: TYPES.DISABLE_USER_FOR_ADMIN,
        payload: { token, userId },
      });
    },
    enableUserMFA: (userId: number, token: string) => {
      dispatch({
        type: TYPES.ENABLE_USER_MFA_FOR_ADMIN,
        payload: { token, userId },
      });
    },
    disableUserMFA: (userId: number, token: string) => {
      dispatch({
        type: TYPES.DISABLE_USER_MFA_FOR_ADMIN,
        payload: { token, userId },
      });
    },
  };
};

const mapStateToProps = (state: any) => {
  return {
    isLoaded: state.admin.isLoaded,
    users: state.admin.users,
    token: state.token.key,
  };
};

const UsersTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersTable);

export default UsersTableContainer;
