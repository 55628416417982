import Axios, { AxiosPromise } from "axios";

/**
 * Returns anomalies
 *
 * @export
 * @param {*} action
 * @returns {AxiosPromise}
 */
export function getAnomalies(action: any): AxiosPromise {
  let request = process.env.REACT_APP_LATEST_API + "/admin/pressure-anomalies";
  const config = {
    params: action.payload.filter,
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 30000,
  };
  return Axios.get(request, config);
}

export function updateAnomaly(action: any): AxiosPromise {
  let request = process.env.REACT_APP_LATEST_API + "/admin/pressure-anomalies";

  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 20000,
  };
  const body = action.payload.data;

  return Axios.post(request, body, config);
}

export function getAnomalyTypes(action: any): AxiosPromise {
  let request =
    process.env.REACT_APP_LATEST_API +
    "/admin/pressure-anomalies/anomaly-types";
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 30000,
  };

  const result = Axios.get(request, config);
  return result;
}

export function upsertAnomalyType(action: any): AxiosPromise {
  let request =
    process.env.REACT_APP_LATEST_API +
    "/admin/pressure-anomalies/anomaly-types";
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 30000,
  };
  const body = action.payload.data;
  return Axios.post(request, body, config);
}

export function deleteAnomalyType(action: any): AxiosPromise {
  let request = `${process.env.REACT_APP_LATEST_API}/admin/pressure-anomalies/anomaly-types/${action.payload.data.id}`;
  const config = {
    headers: { Authorization: "Bearer " + action.payload.token },
    timeout: 30000,
  };
  return Axios.delete(request, config);
}
