import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { format, startOfMonth, addDays, subDays } from "date-fns";
import styled from "styled-components";
import { Bar } from "react-chartjs-2";

import { ScalePage } from "./ScalePage";
import { DateTimePicker } from "../../components/DateTimePicker";
import { Spinner } from "../../components/Spinner";
import { WasteFractionDoughnutCharts } from "./WasteFractionDoughnutCharts";
import { singleEffectiveGroupSelector } from "../../selectors/groups";
import * as TYPES from "../../constants/actionTypes";

interface CardWrapperProps {
  flex: number;
}

const CardWrapper = styled.div<CardWrapperProps>`
  padding: 20px;
`;

const DateTimeContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2px;
`;

interface RowProps {
  justifyContent?: string;
}

const Row = styled.div<RowProps>`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "space-evenly"};
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
`;

interface ChartRowProps {
  justifyContent?: string;
}

const ChartRow = styled.div<ChartRowProps>`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "space-evenly"};
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 0;
`;

const ChartTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  position: relative;
  top: 60px;
`;

const SmallChartTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

const NoResultsWrapper = styled.div`
  display: block;
  margin: 100px 50px;

  h2 {
    font-size: 16px;
    margin-bottom: 25px;
  }
`;

interface TextLinkProps {
  selected: boolean;
}

const TextLink = styled.div<TextLinkProps>`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => (props.selected ? "#000000" : "#0d467d")};
  padding-right: 14px;
  text-decoration: ${(props) => (props.selected ? "initial" : "underline")};
  cursor: pointer;
`;

const SmallChartRow = styled.div<ChartRowProps>`
  display: flex;
  width: 33%;
  flex-direction: row;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "space-evenly"};
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 0;
`;

const SmallChartTitlesWrapper = styled.div<ChartRowProps>`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "space-around"};
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 0;
`;

const SmallChartsWrapper = styled.div`
  display: flex;
`;

const DoughnutCharts = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > ${ChartTitle} {
    position: static;
    margin: 4rem 0 2rem;
    width: 100%;
  }
`;

const SeparatorLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: #d8d8d8;
  margin-top: 30px;
  margin-bottom: 10px;
  position: relative;
  top: 25px;
`;

enum SelectedTimeFrame {
  THIS_MONTH = 0,
  LAST_30_DAYS = 1,
  LAST_90_DAYS = 2,
}

// TODO: Some of these options might be deprecated after upgrading ChartJS v1 -> v4
function getChartOptions(unit: string): any {
  return {
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    hover: {
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
    datasets: {
      bar: {
        barThickness: 20,
        maxBarThickness: 20,
      },
    },
    indexAxis: "y",
    layout: {
      autoPadding: true,
    },
    scales: {
      x: {
        padding: 0,
        position: "top",
        ticks: {
          callback: (value: number) => {
            return `${value} kg`;
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          padding: 80,
          boxWidth: 30,
          fontSize: 15,
        },
      },
      tooltip: {
        xAlign: "center",
        yAlign: "bottom",
        mode: "index",
        intersect: true,
        backgroundColor: "white",
        titleColor: "black",
        titleFont: {
          size: 15,
        },
        bodyColor: "black",
        bodyFontSize: {
          size: 15,
        },
        bodySpacing: 8,
        padding: 15,
        caretSize: 15,
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderWidth: 1,
        callbacks: {
          label: (context: any) =>
            `${context.dataset.label} ${context.raw} ${unit}`,
        },
      },
    },
  };
}

function getSmallChartOptions(unit: string): any {
  return {
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    hover: {
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
    indexAxis: "y",
    plugins: {
      legend: {
        display: false,
        position: "top",
        labels: {
          padding: 80,
          boxWidth: 30,
          fontSize: 15,
        },
      },
      tooltip: {
        xAlign: "right",
        yAlign: "top",
        enabled: true,
        mode: "index",
        intersect: true,
        backgroundColor: "white",
        titleColor: "black",
        titleFont: {
          size: 15,
        },
        titleMarginBottom: 5,
        bodyColor: "black",
        bodyFont: {
          size: 15,
        },
        bodySpacing: 8,
        padding: 10,
        caretSize: 5,
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderWidth: 1,
        callbacks: {
          label: (context: any) => `${context.label} ${context.raw} ${unit}`,
        },
      },
    },
  };
}

export function ScaleOverview() {
  const group = useSelector(singleEffectiveGroupSelector);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(
    SelectedTimeFrame.THIS_MONTH
  );
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(addDays(new Date(), 1));
  const token = useSelector((state: any) => state.token.key);
  const isFetching = useSelector((state: any) => state.scale.isFetching);
  const scaleResults = useSelector((state: any) => state.scale.results);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleGroupSelection = useCallback(() => {
    if (group) {
      const endDateIncludingLastDay = addDays(endDate, 1);
      dispatch({
        type: TYPES.FETCH_SCALE_RESULTS,
        payload: {
          group: group.guid,
          start: format(startDate, "yyyy-MM-dd"),
          end: format(endDateIncludingLastDay, "yyyy-MM-dd"),
          token,
        },
      });
    }
  }, [group, token, dispatch, endDate, startDate]);

  useEffect(() => {
    if (group) {
      handleGroupSelection();
    }
  }, [group, handleGroupSelection]);

  function handleSelectedTimeFrameChange(timeFrame: SelectedTimeFrame) {
    if (!group) {
      return;
    }

    setSelectedTimeFrame(timeFrame);
    const now = new Date();
    switch (timeFrame) {
      case SelectedTimeFrame.THIS_MONTH: {
        setStartDate(startOfMonth(now));
        setEndDate(addDays(now, 1));
        break;
      }
      case SelectedTimeFrame.LAST_30_DAYS: {
        setStartDate(subDays(now, 30));
        setEndDate(addDays(now, 1));
        break;
      }
      case SelectedTimeFrame.LAST_90_DAYS: {
        setStartDate(subDays(now, 90));
        setEndDate(addDays(now, 1));
        break;
      }
    }
    dispatch({
      type: TYPES.FETCH_SCALE_RESULTS,
      payload: {
        group: group.guid,
        start: format(startDate, "yyyy-MM-dd"),
        end: format(endDate, "yyyy-MM-dd"),
        token,
      },
    });
  }

  // The dataset object is returned from the backend, but it is written for an older version of chartjs.
  // This function wrangles the dataset to be compatible with the current version of chartjs.
  // TODO: Refactor the backend to return the raw data only, then parse it in the frontend.
  const parseDataset = (dataset: any) => {
    return dataset.reduce((acc: any, obj: any) => {
      Object.keys(obj).forEach((key) => {
        if (!acc[key]) acc[key] = [];
        acc[key].push(obj[key]);
      });
      return acc;
    }, {});
  };

  const dumpedPerUserGroup = {
    labels: scaleResults ? scaleResults.userGroups : [],
    datasets: scaleResults ? scaleResults.wasteFractions : [],
  };

  const totalDumped = {
    labels: scaleResults
      ? scaleResults.totalDumped.map((dumpData: any) => dumpData.label)
      : [],
    datasets: [scaleResults ? parseDataset(scaleResults.totalDumped) : {}],
  };

  const timesDumped = {
    labels: scaleResults
      ? scaleResults.timesDumped.map((dumpData: any) => dumpData.label)
      : [],
    datasets: [scaleResults ? parseDataset(scaleResults.timesDumped) : {}],
  };

  const uniqueKeysUsed = {
    labels: scaleResults
      ? scaleResults.uniqueKeysUsed.map((dumpData: any) => dumpData.label)
      : [],
    datasets: [scaleResults ? parseDataset(scaleResults.uniqueKeysUsed) : {}],
  };

  const dumpedPerWasteFraction = {
    ...scaleResults,
    totalDumped,
    timesDumped,
    uniqueKeysUsed,
    dumpedPerUserGroup,
  };

  return (
    <ScalePage bodyBackgroundColor={"#fafbfc"}>
      <CardWrapper flex={1}>
        <Row justifyContent={"flex-start"}>
          <TextLink
            selected={selectedTimeFrame === SelectedTimeFrame.THIS_MONTH}
            onClick={() => {
              handleSelectedTimeFrameChange(SelectedTimeFrame.THIS_MONTH);
            }}
          >
            {t("scale.overview.this_month", "This Month")}
          </TextLink>
          <TextLink
            selected={selectedTimeFrame === SelectedTimeFrame.LAST_30_DAYS}
            onClick={() => {
              handleSelectedTimeFrameChange(SelectedTimeFrame.LAST_30_DAYS);
            }}
          >
            {t("scale.overview.last_30_days", "Last 30 days")}
          </TextLink>
          <TextLink
            selected={selectedTimeFrame === SelectedTimeFrame.LAST_90_DAYS}
            onClick={() => {
              handleSelectedTimeFrameChange(SelectedTimeFrame.LAST_90_DAYS);
            }}
          >
            {t("scale.overview.last_90_days", "Last 90 days")}
          </TextLink>
          <DateTimeContainer>
            <DateTimePicker
              eventLog={true}
              selectedDate={startDate}
              handleChange={setStartDate}
              name={"start_date"}
            />
            <div
              style={{
                width: "32px",
                textAlign: "center",
              }}
            >
              -
            </div>
            <DateTimePicker
              eventLog={true}
              selectedDate={endDate}
              handleChange={setEndDate}
              name={"end_date"}
            />
          </DateTimeContainer>
        </Row>
        {isFetching ? (
          <Spinner position={"relative"} z={0} />
        ) : (
          scaleResults && (
            <>
              {scaleResults && scaleResults.totalDumped.length > 0 ? (
                <>
                  <SmallChartTitlesWrapper>
                    <SmallChartTitle>
                      {t("scale.overview.wasteDumped", "Waste Dumped")}
                    </SmallChartTitle>
                    <SmallChartTitle>
                      {t("scale.overview.timesDumped", "Times Dumped")}
                    </SmallChartTitle>
                    <SmallChartTitle>
                      {t("scale.overview.uniqueKeysUsed", "Unique Keys Used")}
                    </SmallChartTitle>
                  </SmallChartTitlesWrapper>
                  <SmallChartsWrapper>
                    <SmallChartRow justifyContent={"flex-start"}>
                      <Bar
                        data={totalDumped}
                        height={totalDumped.labels.length * 30 + 75}
                        options={getSmallChartOptions("kg")}
                      />
                    </SmallChartRow>
                    <SmallChartRow justifyContent={"flex-start"}>
                      <Bar
                        data={timesDumped}
                        height={timesDumped.labels.length * 30 + 75}
                        options={getSmallChartOptions("times")}
                      />
                    </SmallChartRow>
                    <SmallChartRow justifyContent={"flex-start"}>
                      <Bar
                        data={uniqueKeysUsed}
                        height={uniqueKeysUsed.labels.length * 30 + 75}
                        options={getSmallChartOptions("times")}
                      />
                    </SmallChartRow>
                  </SmallChartsWrapper>
                  <SeparatorLine />
                  <ChartTitle>
                    {t(
                      "scale.overview.wasteDumpedByUserGroup",
                      "Waste Dumped By User Group"
                    )}
                  </ChartTitle>
                  <ChartRow>
                    <Bar
                      data={dumpedPerUserGroup}
                      height={dumpedPerUserGroup.labels.length * 90 + 150}
                      options={getChartOptions("kg")}
                    />
                  </ChartRow>
                  <SeparatorLine />
                  <DoughnutCharts>
                    <ChartTitle>
                      {t(
                        "scale.overview.waste",
                        "Groups' dumped waste by waste fraction (kg)"
                      )}
                    </ChartTitle>
                    <WasteFractionDoughnutCharts {...dumpedPerWasteFraction} />
                  </DoughnutCharts>
                </>
              ) : (
                <NoResultsWrapper>
                  <h2>
                    {t(
                      "scale.overview.scalesNotUsedYet.heading",
                      "Scales have not been used yet"
                    )}
                  </h2>
                  <p>
                    {t(
                      "scale.overview.scalesNotUsedYet.description",
                      "Here you'll see real-time statistics on how " +
                        "much waste has been dumped by each of your " +
                        "user groups."
                    )}
                  </p>
                </NoResultsWrapper>
              )}
            </>
          )
        )}
      </CardWrapper>
    </ScalePage>
  );
}
